<template>
  <div v-if="fname?.length">
    <template v-if="blok.asset?.title">
      <figure class="asset-image-with-caption">
        <nuxt-img
          :src="fname"
          :width="dimensions[0]"
          :height="dimensions[1]"
          :loading="blok.lazy ? 'lazy' : 'eager'"
          :alt="blok.asset?.alt"
          :sizes="sizes"
          provider="storyblok"
        />
        <figcaption>
          <figcaption>{{ blok.asset?.title }}</figcaption>
        </figcaption>
      </figure>
    </template>
    <template v-else>
      <template v-if="!blok.asset?.is_external_url && !isSvg">
        <nuxt-img
          :src="fname"
          :width="dimensions[0]"
          :height="dimensions[1]"
          :loading="blok.lazy ? 'lazy' : 'eager'"
          :alt="blok.asset?.alt"
          :sizes="sizes"
          class="asset-image"
          provider="storyblok"
        />
      </template>
      <template v-else-if="isSvg && svgForceDimensions">
        <img
          :src="fname"
          :loading="blok.lazy ? 'lazy' : 'eager'"
          :alt="blok.asset?.alt"
          :width="dimensions[0]"
          :height="dimensions[1]"
          class="asset-image"
        />
      </template>
      <template v-else>
        <img
          :src="fname"
          :loading="blok.lazy ? 'lazy' : 'eager'"
          :alt="blok.asset?.alt"
          class="asset-image"
        />
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  preset: {
    type: String,
    default: "default",
  },
  sizeHint: {
    type: Number,
    required: false,
    default: 100,
  },
  svgForceDimensions: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const sizes = computed(() => {
  return `phone:100vw desktop:${props.sizeHint}vw`;
});

const dimensions = computed(() => {
  const dxd = props.blok.asset?.filename?.split("/")
    .filter((el) => el.match(/\d+x\d+/))[0];
  return !props.blok.asset?.is_external_url && dxd ? dxd.split("x") : "";
});

const aspectRatio = computed(() => {
  return `${dimensions.value[0]}/${dimensions.value[1]}`;
});

const fname = computed(() => {
  return props.blok?.asset?.filename?.replace(/s3\.amazonaws\.com\//, "");
});

const isSvg = computed(() => {
  return /\.svg$/.test(props.blok?.asset?.filename);
});
</script>

<style lang="scss" scoped>
img {
  max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: v-bind("aspectRatio");
  object-fit: cover;
  object-position: center center;
}
// With border-radius
.news-content-inner {
  img {
    border-radius: $radius-l;
  }
}
</style>
